/* eslint-disable max-len */

import './ThesisDetails.css';
import UI from '@ui/UI';
import FormThesis from '@components/Forms/FormThesis';
import TableThesisHistory from '@components/UIElements/TableThesisHistory';
import {useEffect, useState} from 'react';
import {useFormFields} from '@hooks/form-hook.js';
import {useParams} from 'react-router-dom';
import {Row, Col, Badge, Button, Card, Modal, CloseButton, Stack, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';

function ThesisDetails(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const redirectParams = useParams();
  const id = redirectParams.id;
  const [fields, handleChange] = useFormFields([
    {tmg_reason: '', error: true},
  ]);
  // useState
  const [xhrLoad, setXhrload] = useState(true);
  const [isSimReportShown, setIsSimReportShown] = useState(false);
  const [isConfirmShown, setIsConfirmShown] = useState(false);
  const [simReportURL, setSimReportURL] = useState('');
  const [toasts, setToasts] = useState([]);
  const [simScoring, setSimScoring] = useState(undefined);
  const [isMainAuthor, setIsMainAuthor] = useState(true);
  const [isPD, setIsPD] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [labelGroups, setLabelGroups] = useState([]);
  // useSelector
  const language = useSelector((state) => state.language);
  const thesis = useSelector((state) => state.thesis);
  const tmgUserInfo = useSelector((state) => state.tmgUserInfo);
  const role = useSelector((state) => state.tmgCurrentRole);
  // custom
  const waitingTime = 28;

  // -------- initializing / useEffect --------

  /* fetch tmg user info if not already available in Redux store */
  useEffect(() => {
    if (!tmgUserInfo || Object.keys(tmgUserInfo).length === 0) {
      getTmgUserInfo();
    }
  }, [tmgUserInfo]);

  /* fetch thesis details once role is available */
  useEffect(()=> {
    if (role) getThesisDetails();
  }, [role]);

  /* set further information on user when thesis or tmgUserInfo changes
     - check if current user is teacher and/or program manager of current thesis
     - check if current user is main author of thesis */
  useEffect(() => {
    if (Object.keys(thesis).length > 0) {
      // check if current user is teacher and/or program manager of current thesis
      if (tmgUserInfo.employee_id) {
        setIsPD(tmgUserInfo.employee_id === thesis.program_manager?.employee_fk);
        setIsTeacher(thesis.reviewers.map((reviewer) => reviewer.employee_fk).includes(tmgUserInfo.employee_id));
      // check if current user is main author of thesis
      } else if (tmgUserInfo.matricle_integer) {
        if (thesis.coauthors?.length > 0) {
          setIsMainAuthor(thesis.coauthors.findIndex((student) => tmgUserInfo.matricle_integer === student.sub_mat_nr) === -1);
        }
        if (thesis.coauthors?.length === 0) {
          setIsMainAuthor(true);
        }
      } else {
        return;
      }
      if (thesis.final_draft.legal_agreement?.length > 0) groupLabels();
    }
  }, [thesis, tmgUserInfo]);

  /* delete toasts after given time period */
  useEffect(() => {
    const visibleToasts = toasts.filter((toast) => toast.show);
    if (visibleToasts.length > 0) {
      setTimeout(() => {
        const updatedToasts = toasts.map((toast, ix) => {
          toast.show = false;
          return toast;
        });
        setToasts(updatedToasts);
      }, 7000);
    }
  }, [toasts]);

  /* add css class to body if similarity report open to prevent double scroll bar */
  useEffect(() => {
    if (isSimReportShown) {
      document.querySelector('body').classList.add('no-double-scrollbar');
    } else {
      document.querySelector('body').classList.remove('no-double-scrollbar');
    }
  }, [isSimReportShown]);

  // -------- API calls --------

  /* fetch user info regarding thesis management application */
  const getTmgUserInfo = () => {
    BA.ajax({
      url: '/auth/userinfo',
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setXhrload(false);
      } else if (response.data) {
        const tmgUserInfo = response.data;
        if (tmgUserInfo.roles?.length > 0 ) {
          dispatch({
            type: 'set_tmg_user_info',
            tmgUserInfo: tmgUserInfo,
          });
          dispatch({
            type: 'set_tmg_current_role',
            tmgCurrentRole: tmgUserInfo.roles[0],
          });
        }
      }
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
    });
  };

  /* fetch thesis details */
  const getThesisDetails = () => {
    setXhrload(true);
    BA.ajax({
      url: `/thesis/` + id,
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
        dispatch({
          type: 'set_thesis',
          thesis: {},
        });
      } else if (response.data) {
        const thesis = response.data;
        // sort drafts by version
        thesis.drafts = thesis.drafts.sort((a, b) => {
          if (a.version < b.version) {
            return -1;
          }
          if (a.version > b.version) {
            return 1;
          }
          return 0;
        });
        dispatch({
          type: 'set_thesis',
          thesis: thesis,
        });
        setSimScoring(thesis.final_draft?.similarity_scoring);
      }
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
    });
  };

  /* download thesis pdf file */
  const downloadThesis = () => {
    setXhrload(true);
    BA.ajax({
      url: `/teacher/thesis/${thesis.tid}/final-draft/download`,
      tmgapi: true,
      responseType: 'blob',
      responseEncoding: 'binary',
      acceptHeader: 'application/pdf',
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else {
        const fileURL = URL.createObjectURL(response, {type: 'application/pdf'});
        window.open(fileURL, '_blank');
      }
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* downlad attachment file (including aid list) */
  const downloadAttachment = (ev) => {
    const filename = thesis.attachments
        .filter((att) => att.tid === parseInt(ev.currentTarget.id))
        .map((att) => att.filename_orig || att.filename)[0];
    setXhrload(true);
    BA.ajax({
      url: `/teacher/thesis/${thesis.tid}/attachment/${ev.currentTarget.id}/download`,
      tmgapi: true,
      responseType: 'blob',
      responseEncoding: 'binary',
      acceptHeader: 'application/octet-stream',
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else {
        const fileURL = URL.createObjectURL(response, {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* download similarity report pdf file */
  const downloadSimReport = () => {
    setXhrload(true);
    BA.ajax({
      url: `/teacher/thesis/${thesis.tid}/similarity-report/download`,
      tmgapi: true,
      responseType: 'blob',
      responseEncoding: 'binary',
      acceptHeader: 'application/pdf',
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else {
        const fileURL = URL.createObjectURL(response, {type: 'application/pdf'});
        window.open(fileURL, '_blank');
      }
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* accept thesis */
  const acceptThesis = () => {
    setXhrload(true);
    const data = {
      thesis_tid: thesis.tid,
    };
    BA.ajax({
      url: `/teacher/thesis/accept`,
      tmgapi: true,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else if (response.data) {
        setToasts([{
          variant: 'success',
          message: _(`app.thesis.thesis_accept_success`),
          show: true}]);
        dispatch({
          type: 'set_thesis',
          thesis: response.data,
        });
      }
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* accept blocking request */
  const acceptBlocking = () => {
    setXhrload(true);
    BA.ajax({
      url: `/manager/thesis/${thesis.tid}/accept`,
      tmgapi: true,
      method: 'PUT',
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
        setXhrload(false);
      } else if (response.data) {
        setToasts([{
          variant: 'success',
          message: _(`app.thesis.blocking_states.4.description`),
          show: true}]);
        getThesisDetails();
      }
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* reject blocking request */
  const rejectBlocking = () => {
    setXhrload(true);
    BA.ajax({
      url: `/manager/thesis/${thesis.tid}/reject`,
      tmgapi: true,
      method: 'PUT',
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
        setXhrload(false);
      } else if (response.data) {
        setToasts([{
          variant: 'success',
          message: _(`app.thesis.blocking_states.5.description`),
          show: true}]);
        getThesisDetails();
      }
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* get URL for similarity report viewer */
  const getSimReportURL = () => {
    setXhrload(true);
    BA.ajax({
      url: `/teacher/thesis/draft/${thesis.final_draft.tid}/launch-url?lang=` + (language && language != '' ? language : 'de'),
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else if (response.data) {
        setSimReportURL(response.data);
        setIsSimReportShown(true);
      }
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* set thesis back to start */
  const setBackToStart = (ev) => {
    setXhrload(true);
    setIsConfirmShown(false);
    const data = {
      thesis_tid: thesis.tid,
      archived_reason: document.querySelector('#tmg_reason')?.value,
    };
    BA.ajax({
      url: `/teacher/thesis/reject`,
      tmgapi: true,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
        setXhrload(false);
      } else if (response.data) {
        setToasts([{
          variant: 'success',
          message: _(`app.thesis.back_to_start_success`),
          show: true}]);
        getThesisDetails();
      }
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* get current similarity scoring of draft */
  const getSimScoring = () => {
    setXhrload(true);
    BA.ajax({
      url: `/teacher/thesis/draft/${thesis.final_draft.tid}/similarity-score`,
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else if (response.data) {
        setSimScoring(response.data);
      }
      setXhrload(false);
    }).catch((e)=>{
      setToasts([{
        variant: 'error',
        message: `${_('app.thesis.response.error')}: ${e}`,
        show: true}]);
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  // -------- Helper functions --------

  /* close toast with error message */
  const handleClose = (i) => {
    const updatedToasts = toasts.map((toast, ix)=>{
      if (i === ix) {
        toast.show = false;
      }
      return toast;
    });
    setToasts(updatedToasts);
  };

  /* evaluate conditions to show submit form
     - student: thesis state "CREATED" and is main author */
  const showSubmitForm = () => {
    return ((role === 'student' && thesis?.state?.tid === 1 && isMainAuthor));
  };

  /* evaluate conditions to set thesis back to start
     - admin: thesis state between "SUBMITTED" (2) and "THESIS_ACCEPT" (6)
     - teacher: thesis state between "SUBMITTED" (2) and "SIMILARITY_DONE" (5) */
  const canSetBackToStart = () => {
    if (role === 'admin') return (thesis.state?.tid > 1) && (thesis.state?.tid < 7);
    if (isTeacher) {
      if (thesis.type.tid === 3) {
        const primaryReviewer = thesis.reviewers.find((el) => el.reviewer_role?.role_id === 4);
        return ((primaryReviewer.employee_fk === tmgUserInfo.employee_id) && (thesis.state?.tid > 1) && (thesis.state?.tid < 6));
      } else {
        return (thesis.state?.tid > 1) && (thesis.state?.tid < 6);
      }
    }
  };

  /* evaluate conditions to accept thesis
     - admin: thesis state is "SIMILARITY_DONE" (5)
     - teacher: thesis state is "SIMILARITY_DONE" (5) and if dissertation: user is first reviewer */
  const canAcceptThesis = () =>{
    if (role === 'admin') return (thesis.state?.tid === 5);
    if (isTeacher) {
      if (thesis.type.tid === 3) {
        const primaryReviewer = thesis.reviewers.find((el) => el.reviewer_role?.role_id === 4);
        return ((primaryReviewer.employee_fk === tmgUserInfo.employee_id) && thesis.state.tid === 5);
      } else {
        return thesis.state.tid === 5;
      }
    }
  };

  /* evaluate conditions to download thesis */
  const canDownloadThesis = () => {
    return thesis.final_draft?.draft_pdf?.filepath ? true : false;
  };

  /* evaluate conditions to download similarity report */
  const canDownloadSimReport = () => {
    return ((role === 'admin') || isTeacher) && thesis.final_draft?.is_similarity_report_pdf_downloaded;
  };

  /* evaluate conditions to accept or deny blocking request
     - admin: blocking state is "PENDING" and waiting time has passed
     - program manager: blocking state is "STUDENT_REQUESTED" and thesis state is >= "THESIS_ACCEPT" */
  const canBlock = () => {
    if (role === 'admin') return thesis.blocking_state?.tid === 3 && hasWaitingTimePassed();
    if (isPD) {
      return thesis.blocking_state?.tid === 2 && thesis.state?.tid >= 6;
    }
    return false;
  };

  /* evaluate conditions to display similarity report
     - admin: similarity state >= "SIMILARITY_REPORT_COMPLETE"
     - teacher: similarity state = "SIMILARITY_REPORT_COMPLETE" */
  const canDisplaySimReport = () => {
    return (role === 'admin' && thesis.final_draft?.similarity_state.tid >= 7) ||
           (isTeacher && thesis.final_draft?.similarity_state.tid === 7);
  };

  /* handle request to set back to start
     - show field to input reason
     - if field correctly filled, show confirm modal */
  const handleBackToStartClick = () => {
    const reason = document.querySelector('#tmg_reason');
    // show input field
    if (reason?.classList.contains('d-none')) {
      reason.classList.remove('d-none');
      return;
    }
    // show modal
    if (fields.findIndex((el) => el.error) === -1) {
      setIsConfirmShown(true);
    } else {
      setToasts([{
        variant: 'error',
        message: `${_('app.thesis.reason')} ${_('app.thesis.validation.error_input_too_short_10')}`,
        show: true}]);
    }
  };

  const handleCloseViewer = () => {
    setIsSimReportShown(false);
    getSimScoring();
  };

  /* check if more days have passed than prescribed waiting time in days */
  const hasWaitingTimePassed = () => {
    const decisionDate = new Date(thesis.blocking_decision_at);
    const today = new Date();
    const difference = Math.floor((today - decisionDate) / 1000 / 60 / 60 / 24);
    return difference > waitingTime;
  };

  /* group legal agreement labels according to preamble */
  const groupLabels = () => {
    // find distinct preamble in legal agreement
    const preambles = thesis.final_draft.legal_agreement.map((label) => label.preamble);
    const distinctPreambles = [...new Set(preambles)];
    // group labels by distinct preambles
    const labelGroups = [];
    distinctPreambles.forEach((preamble) => {
      const labels = thesis.final_draft.legal_agreement.filter((label) => label.preamble === preamble);
      labelGroups.push({preamble, labels});
    });
    setLabelGroups(labelGroups);
  };

  return (
    <Col className="p-0">
      <Row className="p-4 g-0">
        <div className="thesis-details col-12 col-lg-10 offset-lg-1">
          <UI.PageTitle backurl="/member/theses">
            {_('app.thesis.title_details')}
          </UI.PageTitle>
          <div className='toast-container'>
            <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
          </div>
          {xhrLoad && <UI.AjaxLoading />}
          {/* no roles */}
          {!xhrLoad && !role &&
            <Row>
              <Col lg={6}>
                <div className='mt-3'>
                  <UI.InfoBox>
                    <UI.InfoText>
                      {_('app.thesis.no_roles')}
                    </UI.InfoText>
                  </UI.InfoBox>
                </div>
              </Col>
            </Row>
          }
          {!xhrLoad && role &&
            <>
              {/* no result */}
              {!thesis.tid &&
                <Row>
                  <Col lg={6}>
                    <div className='mt-3'>
                      <UI.InfoBox>
                        <UI.InfoText>
                          {_('app.thesis.no_result')}
                        </UI.InfoText>
                      </UI.InfoBox>
                    </div>
                  </Col>
                </Row>
              }
              {/* details */}
              {thesis.tid && !showSubmitForm() && <>
                {/* show detailed information */}
                {!isSimReportShown && <>
                  <Row className="mt-4">
                    <Col xxl={9}>
                      {!isMainAuthor &&
                        <div className='mb-3'>
                          <UI.InfoBox>
                            <UI.InfoText>
                              <div>{_(`app.thesis.not_main_author`)}
                                <b> {thesis.student?.firstname} {thesis.student.surname}</b>.
                              </div>
                            </UI.InfoText>
                          </UI.InfoBox>
                        </div>
                      }
                      <Card>
                        <Card.Header as='h5'>{_('app.thesis.thesis_details')}</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.student')}</p>
                            </Col>
                            <Col>
                              <div>
                                <Badge bg="mainmedium me-2" className='mnr-badge'>
                                  {thesis.student?.matricle ? thesis.student.matricle.toString().padStart(8, '0') : ''}
                                </Badge>
                                {thesis.student?.firstname ? thesis.student.firstname + ' ' : ''}
                                {thesis.student?.surname || ''}
                              </div>
                            </Col>
                          </Row>
                          {thesis.coauthors?.length > 0 &&
                        <Row>
                          <Col md={3}>
                            <p>{_('app.thesis.coauthors')}</p>
                          </Col>
                          <Col>
                            {thesis.coauthors.map((coauthor, i) => {
                              return <div key={'coauthor-'+i} className="coauthor-container">
                                <Badge bg="mainmedium me-2" key={'coauthor-' + i} className='mnr-badge'>
                                  {coauthor.sub_mat_nr ? coauthor.sub_mat_nr.toString().padStart(8, '0') : ''}
                                </Badge>
                                {role !== 'student' &&
                                  <>
                                    {coauthor.sub_name || ''}
                                  </>
                                }
                              </div>;
                            })}

                          </Col>
                        </Row>
                          }
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.type')}</p>
                            </Col>
                            <Col>
                              {_(`app.thesis.types.${thesis.type.tid}.long`)}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.language')}</p>
                            </Col>
                            <Col>
                              {language && thesis.language_names && thesis.language_names[language] ?
                                thesis.language_names[language] :
                                thesis.language || _('app.thesis.not_available')}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.title_orig')}</p>
                            </Col>
                            <Col>
                              {thesis.final_draft?.title_original || _('app.thesis.not_available')}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.title_en')}</p>
                            </Col>
                            <Col>{thesis.final_draft?.title_english || _('app.thesis.not_available')}
                            </Col>
                          </Row>
                          {thesis.type.tid === 3 && thesis.cumulative_titles?.length > 0 &&
                        <Row>
                          <Col md={3}>
                            <p>{_('app.thesis.cumulative_work')}</p>
                          </Col>
                          <Col>
                            {thesis.cumulative_titles.map((cuTitle, i) => {
                              return <div key={'cuTitle-' +i}>{cuTitle}</div>;
                            })
                            }
                          </Col>
                        </Row>
                          }
                          {thesis.type.tid === 3 && <>
                            {thesis.language !== 'en' && <Row>
                              <Col md={3}>
                                <p>{_('app.thesis.abstract_orig')}</p>
                              </Col>
                              <Col>{thesis.abstract_original || _('app.thesis.not_available')}
                              </Col>
                            </Row>
                            }
                            <Row>
                              <Col md={3}>
                                <p>{_('app.thesis.abstract_en')}</p>
                              </Col>
                              <Col>{thesis.abstract_english || _('app.thesis.not_available')}
                              </Col>
                            </Row>
                          </>
                          }
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.reviewers')}</p>
                            </Col>
                            <Col>
                              {thesis.reviewers?.length > 0 ?
                                thesis.reviewers.map((reviewer, i) => {
                                  return <div key={'reviewer-' +i}>{reviewer.fullname} ({reviewer.role})</div>;
                                }) : _('app.thesis.not_available')
                              }
                            </Col>
                          </Row>
                          {thesis.blocking_state?.tid > 1 && <>
                            <Row>
                              <Col md={3}>
                                <p>{_('app.thesis.roles.program_manager')}</p>
                              </Col>
                              <Col>
                                {thesis.program_manager?.fullname || _('app.thesis.not_available')}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <p>{_('app.thesis.request_block_years')}</p>
                              </Col>
                              <Col>
                                {thesis.blocking_years ?
                                  `${thesis.blocking_years} ${thesis.blocking_years === 1 ? _('app.thesis.year') : _('app.thesis.years')}` :
                                 _('app.thesis.not_available')
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <p>{_('app.thesis.reason')}</p>
                              </Col>
                              <Col>
                                {thesis.blocking_description || _('app.thesis.not_available')}
                              </Col>
                            </Row>
                          </>
                          }
                          <Row>
                            <Col md={3}>
                              <p>{_('app.thesis.download')}</p>
                            </Col>
                            <Col>
                              {!canDownloadThesis() && !canDownloadSimReport() &&
                                _('app.thesis.not_available')
                              }
                              {(canDownloadThesis() || canDownloadSimReport()) &&
                                <ul>
                                  {canDownloadThesis() && <>
                                    <li>
                                      <a
                                        id="download-pdf-thesis"
                                        className='icon_download'
                                        onClick={downloadThesis}
                                      >
                                        <span>
                                          <b>{thesis.final_draft?.draft_pdf?.filename}</b>
                                        </span>
                                      </a>
                                      <span> ({_('app.thesis.file_type_thesis')})</span>
                                    </li>
                                    { thesis.attachments?.length > 0 && <>
                                      {thesis.attachments.map((a) => {
                                        return <li key={'attachment-' + a.tid}>
                                          <a
                                            id={a.tid}
                                            className='icon_download'
                                            onClick={downloadAttachment}
                                          >
                                            <span>
                                              <b>{a.filename_orig || a.filename}</b>
                                            </span>
                                          </a>
                                          {!a.filename.includes('Sperrbescheid') && <span>
                                            {' '}({a.filename === 'aid_list' ? _('app.thesis.file_type_aid_list') : _('app.thesis.file_type_attachment')})
                                          </span>
                                          }
                                        </li>;
                                      })
                                      }

                                    </>
                                    }
                                  </>
                                  }
                                  {canDownloadSimReport() && <>
                                    <li>
                                      <a
                                        id="download-sim-report"
                                        onClick={downloadSimReport}
                                        className='icon_download'
                                      >
                                        <span>
                                          <b>{_('app.thesis.similarity_report')}</b>
                                        </span>
                                      </a>
                                    </li>
                                  </>
                                  }
                                </ul>
                              }
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xxl={9}>
                      <Card>
                        <Card.Header as='h5'>{_('app.thesis.state')}</Card.Header>
                        <Card.Body>
                          <Row>
                            {(['admin', 'student'].includes(role) || isTeacher) &&
                              <Col md={4}>
                                <p>{_('app.thesis.submission')}</p>
                                <UI.InfoText>{_(`app.thesis.thesis_states.${thesis.state.tid}.description`)}</UI.InfoText>
                              </Col>
                            }
                            {(role === 'admin' || (isPD && thesis.blocking_state.tid > 1 && thesis.state.tid > 5) || (role === 'student' && thesis.type.tid !== 1)) &&
                              <Col md={4}>
                                <p>{_('app.thesis.blocking')}</p>
                                <UI.InfoText>
                                  <div>{_(`app.thesis.blocking_states.${thesis.blocking_state.tid}.description`)}</div>
                                </UI.InfoText>
                              </Col>
                            }
                            {(role === 'admin' || isTeacher) &&
                              <Col md={4}>
                                <p>{_('app.thesis.similarity_report')}</p>
                                <UI.InfoText>
                                  {_(`app.thesis.similarity_states.${thesis.final_draft.similarity_state.tid}.description`)}
                                </UI.InfoText>
                              </Col>
                            }
                          </Row>
                          {(role === 'admin' || isTeacher || isPD) &&
                          <Row>
                            {(role === 'admin' || isTeacher) &&
                            <Col md={4}>
                              <UI.FormControl
                                className="ps-3 d-none mt-2"
                                type="text"
                                name="tmg_reason"
                                handler={handleChange}
                                value={fields[0].tmg_reason}
                                validation
                                placeholder={_('app.thesis.reason_back_to_start_placeholder')}
                              />
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    {_('app.thesis.back_to_start_overlay')}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  id="back-to-start"
                                  type="submit"
                                  className="thesis-button"
                                  variant={canSetBackToStart() ? 'primary' : 'secondary'}
                                  onClick={handleBackToStartClick}
                                >
                                  {_('app.thesis.back_to_start')}
                                </Button>
                              </OverlayTrigger>
                              { tmgUserInfo.employee_id &&
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      {_('app.thesis.accept_thesis_overlay')}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    id="accept-thesis"
                                    type="button"
                                    className="thesis-button"
                                    variant={canAcceptThesis() ? 'primary' : 'secondary'}
                                    onClick={canAcceptThesis() ? acceptThesis : undefined}
                                  >
                                    {_('app.thesis.accept_thesis')}
                                  </Button>
                                </OverlayTrigger>
                              }
                            </Col>
                            }
                            {(role === 'admin' || (isPD && thesis.blocking_state.tid > 1 && thesis.state.tid > 5)) &&
                              <Col md={4}>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      {_('app.thesis.blocking_accept_overlay')}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    id="accept-blocking"
                                    type="button"
                                    className="thesis-button"
                                    variant={canBlock() ? 'primary' : 'secondary'}
                                    onClick={canBlock() ? acceptBlocking : undefined}
                                  >
                                    {_('app.thesis.blocking_accept')}
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      {_('app.thesis.blocking_reject_overlay')}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    id="reject-blocking"
                                    type="button"
                                    className="thesis-button"
                                    variant={canBlock() ? 'primary' : 'secondary'}
                                    onClick={canBlock() ? rejectBlocking : undefined}
                                  >
                                    {_('app.thesis.blocking_reject')}
                                  </Button>
                                </OverlayTrigger>
                              </Col>
                            }
                            {(role === 'admin' || isTeacher) &&
                              <Col md={4}>
                                <Stack direction="horizontal" gap={2}>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        {_('app.thesis.show_viewer_overlay')}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      id="display-sim-report"
                                      type="button"
                                      className="thesis-button"
                                      variant={canDisplaySimReport() ? 'primary' : 'secondary'}
                                      onClick={canDisplaySimReport() ? getSimReportURL : undefined}
                                    >
                                      {_('app.thesis.show_viewer')}
                                    </Button>
                                  </OverlayTrigger>
                                  <div className='thesis-sim-scoring'>
                                    {canDisplaySimReport() ? simScoring + ' %' : 'n/a'}
                                  </div>
                                </Stack>
                              </Col>
                            }
                          </Row>
                          }
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  {(['admin', 'student'].includes(role) || isTeacher) &&
                    <TableThesisHistory drafts={thesis.drafts}/>
                  }
                  {['admin', 'student'].includes(role) && thesis.final_draft?.legal_agreement?.length > 0 &&
                    <Row className="my-2">
                      <Col xxl={9}>
                        <Card>
                          <Card.Header as='h5'>{_('app.thesis.legal_confirmation')}</Card.Header>
                          <Card.Body>
                            {labelGroups && labelGroups.length > 0 && labelGroups.map((labelGroup, index) => {
                              return <Row key={'labelgroup-' + index}>
                                <Col>
                                  <p>{labelGroup.preamble}</p>
                                  <ul>
                                    {labelGroup.labels.map((label)=>
                                      <li key={'label-' + label.tid}>
                                        <div className='icon_checkbox'>
                                          <span>{label.text}</span>
                                        </div>
                                      </li>,
                                    )}
                                  </ul>
                                </Col>
                              </Row>;
                            })}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  }
                </>}
                {/* show similarity report viewer */}
                { ((role === 'admin') || isTeacher) && simReportURL && isSimReportShown &&
                  <Modal show={true} id="thesis-viewer">
                    <Modal.Header className="pt-2 pb-2">
                      <Modal.Title>{_('app.thesis.viewer')}</Modal.Title>
                      <CloseButton onClick={handleCloseViewer} className="btn-close-white" />
                    </Modal.Header>
                    <Modal.Body>
                      <iframe
                        src={simReportURL}
                        className="w-full"
                        height="1024px"
                      ></iframe>
                    </Modal.Body>
                  </Modal>
                }
              </>
              }
              {/* submit form */}
              {thesis.tid && showSubmitForm() &&
                <FormThesis />
              }
              {/* confirm modal for setting back to start */}
              {isConfirmShown &&
                <UI.ConfirmModal
                  show={isConfirmShown}
                  handler={setBackToStart}
                  handleClose={()=>{
                    setIsConfirmShown(false);
                  }}
                  message={<>
                    <p>{_('app.thesis.confirm_back_to_start')}</p>
                    <div><b>{_('app.thesis.reason')}:</b> {document.querySelector('#tmg_reason')?.value || ''}</div>
                  </>
                  }
                  buttontext={_('app.thesis.confirm_ok')}
                />
              }
            </>
          }
        </div>
      </Row>
    </Col>
  );
}

export default ThesisDetails;
